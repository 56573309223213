<template lang="pug">
.page
  el-card(class="roleInfo")
    .roleName 管理员
    .describe 拥有商场的全部权限
    .permissionTitle 拥有权限
    .permissionList 
      el-tag(class="permissionItem" v-for="item in permissionList" :key="item") {{item}}
  el-card
    el-form(label-width="6em" :label-position="isMobile?'top':'right'")
      el-form-item(label="手机号码")
        el-input(v-model.trim="phone" placeholder="请输入手机号码")
  .footer 
    .left(@click="router.go(-1)") 取消
    .right(@click="submitAddMember" v-loading="loading") 确定
</template>

<script setup>
import { inject, onMounted, computed, ref } from 'vue'
import { currentMarketId } from '@/provider/account'
import { isMobile } from '@/provider/sys'

const { fetch, router, message, ElMessageBox } = inject('global')
const phone = ref('')
const loading = ref(false)
const permissionList = ['人员管理', '基础信息管理', '商场门店管理', '商场设施管理', '营业时间管理']

// 提交新建人员
function submitAddMember() {
  loading.value = true
  if (!phone.value) {
    message.error('请先输入手机号码')
  }
  fetch
    .post(`/boom-mix-biz-service/app/commercialPlazaUserAssoc`, {
      commercialPlazaId: currentMarketId.value,
      phoneNumber: phone.value
    })
    .then(res => {
      message.success('新增人员成功')
      router.go(-1)
    })
    .catch(err => {
      message.error(err.msg || err)
    })
    .finally(() => {
      loading.value = false
    })
}

onMounted(() => {})
</script>

<style lang="less" scoped>
.roleInfo {
  line-height: 30px;
  .roleName,
  .permissionTitle {
    font-size: 18px;
    font-weight: 600;
  }
  .describe {
    padding-left: 10px;
  }
  .permissionList {
    padding-left: 10px;
    .permissionItem {
      margin-right: 10px;
    }
  }
}
.footer {
  text-align: center;
  color: #fff;
  position: fixed;
  bottom: 0;
  z-index: 3000;
  width: 100%;
  line-height: 60px;
  cursor: pointer;
  display: flex;
  .left,
  .right {
    flex: 1;
  }
  .left {
    background-color: #ccc;
  }
  .right {
    background-color: var(--color-red);
  }
}
</style>
